import UIMetricsClient, { UIMetricsClientImpl } from './UIMetricsClient';

interface StubMetalClient {
  metric: {
    // tslint:disable-next-line: no-any
    submit: (metric: any) => void;
  };
}

export default interface MetricsClient {
  client: StubMetalClient;
  ui: UIMetricsClient;
}

// tslint:disable-next-line: max-classes-per-file
export class MetricsClientImpl implements MetricsClient {
  private clientImpl: StubMetalClient;
  private uiImpl: UIMetricsClient;
  get client() {
    return this.clientImpl;
  }
  get ui() {
    return this.uiImpl;
  }

  constructor() {
    (this.clientImpl = {
      metric: {
        submit: () => {
          return;
        },
      },
    }),
      (this.uiImpl = new UIMetricsClientImpl());
  }
}
