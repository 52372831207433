import * as Sentry from '@sentry/browser';

import { getEnv, getReleaseVersion } from '../config';
import { isSyntheticTraffic } from '../routing/index';

import { SentryTags } from './types';

/**
 * The app can send any type/category to Sentry,
 * but the breadcrumb will show in their UI only if type / category
 * are one of their expected values.
 */
export enum BreadcrumbCategory {
  Default = 'default',
  Navigation = 'navigation',
  UiClick = 'ui.click',
  Xhr = 'xhr',
  Beacon = 'beacon',
}

function isUnauthorizedError(event: Sentry.SentryEvent) {
  if (event.extra && event.extra.HttpError) {
    return event.extra.HttpError.statusCode === 401;
  }
  return false;
}

export function lastNBreadcrumbsInclude(event: Sentry.SentryEvent, args: { lastN: number; messages: string[] }) {
  const { messages, lastN } = args;
  const breadcrumbs = event.breadcrumbs || [];
  const maxIdx = breadcrumbs.length - 1;
  for (let i = maxIdx; i >= Math.max(maxIdx - lastN, 0); i--) {
    const breadcrumb = breadcrumbs[i];
    if (messages.includes(`${breadcrumb.message}`)) {
      return true;
    }
  }
  return false;
}

export function isRedirectingOn401(event: Sentry.SentryEvent) {
  const exception = event && event.exception && event.exception.values && event.exception.values[0];
  // Check for both null and undefined with `==` because we don't have a Typescript friendly isNil anywhere
  // tslint:disable-next-line: triple-equals
  if (!exception || exception.value == null || exception.type == null) {
    return false;
  }
  if (!exception.value.startsWith('AbortError') && !['TypeError', 'FailedFetchError'].includes(exception.type)) {
    return false;
  }
  return lastNBreadcrumbsInclude(event, { messages: ['redirect to login'], lastN: 5 });
}

export function beforeSend(
  event: Sentry.SentryEvent,
  hint?: {},
): Sentry.SentryEvent | null | Promise<Sentry.SentryEvent | null> {
  if (isUnauthorizedError(event)) {
    return null;
  }
  if (isRedirectingOn401(event)) {
    return null;
  }
  if (event.exception && event.exception.values) {
    event.exception.values.forEach(sanitizeError);
  }
  return event;
}

export function configureSentry() {
  Sentry.init({
    beforeSend,
    dsn: 'https://71db5a213dd04504bbb874bfcb40b3db@o55978.ingest.sentry.io/5988834',
    environment: getEnv(),
    release: getReleaseVersion(),
    // Only send events in production
    sampleRate: __ENV_PROD__ ? 1 : 0,
  });
  Sentry.configureScope(scope => {
    scope.setTag('synthetic', `${isSyntheticTraffic()}`);
  });
}

export function captureException(e: unknown, tags?: SentryTags) {
  Sentry.withScope(scope => {
    if (tags) {
      Object.entries(tags).forEach(([tagKey, tagValue]) => {
        if (tagValue !== undefined) {
          scope.setTag(tagKey, tagValue);
        }
        if (tagKey === 'source') {
          scope.setFingerprint(['source', tagValue]);
        }
      });
    }
    Sentry.captureException(e);
  });
}

export function captureMessage(message: string, tags?: SentryTags) {
  Sentry.withScope(scope => {
    if (tags) {
      Object.entries(tags).forEach(([tagKey, tagValue]) => {
        if (tagValue !== undefined) {
          scope.setTag(tagKey, tagValue);
        }
        if (tagKey === 'source') {
          scope.setFingerprint(['source', tagValue]);
        }
      });
    }
    Sentry.captureException(message);
  });
}

/**
 * Use this to report severe errors that the dev team should address immediately.
 * e.g. a rendering error caught at the top-level of the app in an error boundary
 */
export function captureFatal(e: unknown, extraTags?: SentryTags) {
  captureException(e, { ...extraTags, report: 'opsgenie-high' });
}

/**
 * Some users are embedding Start on App aggregators like Rambox Pro or Shift.
 * This causes exception's stacktrace to contain the fullpath of user's disk.
 * We need to remove that path due to GDPR concerns.
 * Lukily, all these aggregators use Electron which deploys apps inside app.asar.
 */
export function sanitizeError(e: Sentry.SentryException) {
  if (e.stacktrace && e.stacktrace.frames) {
    e.stacktrace.frames.forEach(frame => {
      if (frame.filename) {
        frame.filename = frame.filename.replace(/^.*app\.asar/, '');
      }
    });
  }
}

export function addBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
  Sentry.addBreadcrumb({
    level: Sentry.Severity.Error,
    timestamp: Date.now() / 1000, // Sentry expects seconds, not ms
    type: 'default',
    ...breadcrumb,
  });
}

/**
 * REF: https://hello.atlassian.net/wiki/spaces/SECURITY/pages/236070634/Can+I+Log+This
 * Also, #observability channel verified this is ok to log in sentry because we host Sentry.
 */
export function setAAID(aaid: string) {
  Sentry.configureScope(scope => {
    scope.setUser({
      id: aaid,
      aaid,
    });
  });
}
