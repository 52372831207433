import { getATLContextUrl } from '@atlassian/atl-context';

import { location } from '../facade/window';

type Config = typeof config;

const config = {
  // tslint:disable-next-line: no-require-imports
  adminUrl: 'https://admin.stg.atlassian.com',
  appVersion: __APP_VERSION__,
  atlassianAccountUrl: 'https://id.stg.internal.atlassian.com',
  directoryServiceUrl: '/gateway/api/directory',
  fullUrl: 'https://start.stg.atlassian.com',
  gasApiHost: 'start.stg.atlassian.com/gateway/api/gasv3/api/v1',
  macUrl: 'https://my.stg.internal.atlassian.com',
  notificationsDrawerIframeUrl: '/notificationsDrawer/iframe.html',
  supportUrl: 'https://support.stg.internal.atlassian.com/',
  wacUrl: 'https://qa-wac.internal.atlassian.com',
  fx3ApiKey: __FX3_API_KEY__,
};

type EnvironmentOverrides = { [env in Env]?: Partial<Config> };

// TS does not distinguish between missing keys and keys with undefined values.
// Don't specify null/undefined for any config values or the object spread will give you an invalid Config in getConfig()
// https://github.com/Microsoft/TypeScript/issues/13195
const environmentOverrides: EnvironmentOverrides = {
  local: {
    directoryServiceUrl: 'https://start.stg.atlassian.com/gateway/api/directory',
    fullUrl: `http://${location().hostname}:3000`,
    notificationsDrawerIframeUrl: 'https://start.atlassian.com/notificationsDrawer/iframe.html',
    fx3ApiKey: 'fc70af2b-6158-47df-bbba-104887155663', // development apiKey
  },
  'stg-apse': {
    atlassianAccountUrl: getATLContextUrl('id'),
    adminUrl: getATLContextUrl('admin'),
    fullUrl: getATLContextUrl('start'),
    macUrl: getATLContextUrl('my'),
    supportUrl: getATLContextUrl('support'),
    wacUrl: getATLContextUrl('wac-cdn'),
  },
  'prod-east': {
    adminUrl: 'https://admin.atlassian.com',
    atlassianAccountUrl: 'https://id.atlassian.com',
    fullUrl: 'https://start.atlassian.com',
    gasApiHost: 'start.atlassian.com/gateway/api/gasv3/api/v1',
    macUrl: 'https://my.atlassian.com',
    supportUrl: 'https://support.atlassian.com',
    wacUrl: 'https://www.atlassian.com',
  },
  'stg-fedm-east': {
    atlassianAccountUrl: getATLContextUrl('id'),
    adminUrl: getATLContextUrl('admin'),
    fullUrl: getATLContextUrl('start'),
    macUrl: getATLContextUrl('my'),
    supportUrl: getATLContextUrl('support'),
    wacUrl: getATLContextUrl('wac-cdn'),
  },
  'prod-fedm-east': {
    atlassianAccountUrl: getATLContextUrl('id'),
    adminUrl: getATLContextUrl('admin'),
    fullUrl: getATLContextUrl('start'),
    macUrl: getATLContextUrl('my'),
    supportUrl: getATLContextUrl('support'),
    wacUrl: getATLContextUrl('wac-cdn'),
  },
};

export function getEnv(): Env {
  if (__BUILD_DEV__ || __ENV_LOCAL__) {
    return 'local';
  }

  const env = window.__env__;

  if (!env) {
    // tslint:disable-next-line:no-console
    console.warn('config: No environment override found.');
  }

  return env;
}

export function getReleaseVersion(): string {
  if (__BUILD_DEV__ || __ENV_LOCAL__) {
    return 'local';
  }
  const versionMeta: HTMLMetaElement | null = document.querySelector('meta[name="release-version"]');
  if (!versionMeta) {
    return 'noversion';
  } else {
    return versionMeta.content || 'noversion';
  }
}

export function getConfig(): Config {
  return {
    ...config,
    ...environmentOverrides[getEnv()],
  };
}
