export enum Task {
  YOUR_WORK_TABS_LOADING = 'yourWorkTabs loading',
  PRODUCTS_ROW_LOADING = 'productsRow loading',
  COLLABGRAPH_ROW_LOADING = 'collabGraphRow loading',
  COLLABGRAPH_OBJECTS_ROW_LOADING = 'collabGraphObjectsRow loading',
}

const TASK_TO_COMPONENT = {
  [Task.YOUR_WORK_TABS_LOADING]: 'yourWorkTabs',
  [Task.PRODUCTS_ROW_LOADING]: 'productsRow',
  [Task.COLLABGRAPH_ROW_LOADING]: 'collabGraphRow',
  [Task.COLLABGRAPH_OBJECTS_ROW_LOADING]: 'collabGraphObjectsRow',
};

const COMPONENT_LOADING_HISTOGRAM_BUCKETS = '500_1000_2000_4000_5000_6000_10000';

export type ErrorReason =
  | 'userStore.hasFailed'
  | 'productStore.hasFailed'
  | 'productStore.hasClientError'
  | 'collabGraphStore.hasFailed'
  | 'collabGraphStore.hasClientError'
  | 'collabGraphObjectsStore.hasFailed'
  | 'collabGraphObjectsStore.hasClientError'
  | 'activityStore.hasFailed'
  | 'activityStore.hasClientError'
  | 'errorBoundary';

interface UIMetricsClient {
  cancelTracking: (task: Task) => void;
  endSuccess: (task: Task) => void;
  // tslint:disable-next-line:no-any
  endWithError: (task: Task, errorMetric: any, reason?: ErrorReason) => void;
  startTracking: (task: Task, startTime?: number) => void;
  stopAllTracking: () => void;
}

/**
 * @deprecated METAL is deprecated, this is just a stub to avoid breaking the build
 */
export class UIMetricsClientImpl implements UIMetricsClient {
  cancelTracking = (task: Task) => {
    return;
  };

  endSuccess = (task: Task) => {
    return;
  };

  // tslint:disable-next-line:no-any
  endWithError = (task: Task, errorMetric: any, reason?: string) => {
    return;
  };

  startTracking = (task: Task, startTime?: number) => {
    return;
  };

  stopAllTracking = () => {
    return;
  };
}

export default UIMetricsClient;
