/**
 * Abstractions over functions of the window object to facilitate mocking in tests.
 */

export function redirect(hostname: string) {
  window.location.assign(hostname);
}

/**
 * This will work provided that the user has the right browser settings, and function is called
 * from a synchronous click handler, and not console, for example
 *
 * Do not call in an asynchronous function: https://stackoverflow.com/a/39387533
 */
export function openLinkInNewTab(hostname: string) {
  window.open(hostname, '_blank');
}

export function browserLocales() {
  if (window.navigator.languages) {
    return window.navigator.languages;
  }

  // Edge, Opera, Safari, IE11
  if (window.navigator.language) {
    return [window.navigator.language];
  }

  throw new Error('Cannot detect browser language!');
}

declare type Event = keyof WindowEventMap;

export function addEventListener<K extends Event>(
  event: K,
  listener: (this: Window, ev: WindowEventMap[K]) => void,
  useCapture: boolean = false,
) {
  window.addEventListener(event, listener, useCapture);
}

export function removeEventListener<K extends Event>(
  event: K,
  listener: (this: Window, ev: WindowEventMap[K]) => void,
  useCapture: boolean = false,
) {
  window.removeEventListener(event, listener, useCapture);
}

export function location(): Location {
  return window.location;
}

export function history(): History {
  return window.history;
}

export function performance(): Performance {
  return window.performance;
}

export function referrerHostName() {
  if (!window.document.referrer) {
    return '';
  }

  const referrerUrl = new URL(window.document.referrer);
  return referrerUrl.hostname;
}

export function browserMetrics() {
  return {
    location: location().href,
    referrer: referrerHostName(),
    screenResolution: `${screen.width} x ${screen.height}`,
    userAgent: navigator.userAgent,
    ...getViewPortDimensions(),
  };
}

export function getViewPortDimensions() {
  const innerHeight = window.innerHeight;
  const innerWidth = window.innerWidth;

  return {
    viewportDimension: `${innerWidth} x ${innerHeight}`,
    viewportHeight: innerHeight,
    viewportPixelDensity: window.devicePixelRatio,
    viewportWidth: innerWidth,
  };
}
